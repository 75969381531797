import React from 'react'
import useTranslation from 'translations/hooks/useTranslation'
import { GrammarCaseKey } from 'translations/types'
import StringUtils from 'utils/string'
import { CategorySlug } from 'types/category'
import LastFilterSettingsSingleItem from './LastFilterSettingsSingleItem'
import type { FilterBase } from 'types/filter'

interface IProps {
  settings: string[]
  supportedSettingsValues: FilterBase[]
}

export default function LastFilterSettingsItem({
  settings,
  supportedSettingsValues,
}: IProps) {
  const { t } = useTranslation()
  const items = supportedSettingsValues.filter((level) => settings.includes(level.slug))

  if (!items.length) {
    return null
  }

  const translateFreelanceCategory = (slug: string) =>
    t(`freelancers.categoryName[${slug}]`, {
      grammarCase: GrammarCaseKey.Other,
    })

  const getTranslatedItems = () => {
    return items.map((item) => {
      if (
        Object.values(CategorySlug).includes(item.slug as CategorySlug) &&
        translateFreelanceCategory(item.slug)?.length
      ) {
        return {
          ...item,
          name: StringUtils.capitalize(translateFreelanceCategory(item.slug)),
        }
      }

      if (item.slug === 'sng') {
        return {
          ...item,
          name: t('sng.tag.sng'),
        }
      }

      if (item.slug === 'sng-support') {
        return {
          ...item,
          name: t('sng.tag.support'),
        }
      }

      if (item.slug === 'manifest') {
        return {
          ...item,
          name: t('manifest.tag'),
        }
      }

      return item
    })
  }

  return (
    <>
      {getTranslatedItems().map((item) => (
        <LastFilterSettingsSingleItem key={item.slug} name={item.name} />
      ))}
    </>
  )
}
