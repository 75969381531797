import React, { useState } from 'react'
import Button, { IButtonProps } from 'components/Button'
import useTranslation from 'translations/hooks/useTranslation'

interface IProps {
  fetchPosts: () => void
  isLoading?: boolean
  color?: IButtonProps['color']
}

const LazyLoadButton: React.FC<IProps> = ({ fetchPosts, color }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  const onClick = async () => {
    setLoading(true)
    await fetchPosts()
    setLoading(false)
  }

  return (
    <Button
      loading={loading}
      color={color}
      onClick={onClick}
      text={t('common.actions.loadMore')}
    />
  )
}

export default LazyLoadButton
